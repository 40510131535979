@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import "~cmt-ui-kit/src/importsCRA.less";

@primary-color: #dc582a;

@font-face {
  font-family: 'MarkPro';
  src: url('./fonts/MarkPro.woff2') format('woff2'),
      url('./fonts/MarkPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MarkProBold';
  src: url('./fonts/MarkPro-Bold.woff2') format('woff2'),
      url('./fonts/MarkPro-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MarkProItalic';
  src: url('./fonts/MarkPro-MediumItalic.woff2') format('woff2'),
      url('./fonts/MarkPro-MediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "fontastic-cmt";
  src:url("./fonts/icons/Fontastic/fontastic-cmt.eot");
  src:url("./fonts/icons/Fontastic/fontastic-cmt.eot?#iefix") format("embedded-opentype"),
    url("./fonts/icons/Fontastic/fontastic-cmt.woff") format("woff"),
    url("./fonts/icons/Fontastic/fontastic-cmt.ttf") format("truetype"),
    url("./fonts/icons/Fontastic/fontastic-cmt.svg#fontastic-cmt") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'MarkPro'; font-weight: normal; font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-header {
  background-color: white;
  box-shadow: 1px 1px 34px -17px black;
  .ant-menu-vertical {
    border: none;
  }
}


.ant-card {
  box-shadow: 0 4px 28px -9px rgba(148,148,148,0.75);
  width: 400px;
  margin: 0 auto;
  border-radius: 5px;

  .ant-card-head {
    .ant-card-head-title {
      font-family: 'MarkProBold';
      text-align: center;
      color: @primary-color;
      font-size: 18px;
    }
  }

  .ant-card-body {
    .cmt-input-icon.ant-input-affix-wrapper {
      padding: 6.5px 11px;
    }

    .cmt-button {
      width: 120px;
    }
  }

  @media screen and (max-width: 991px) {
    max-width: 318px;
  }
}